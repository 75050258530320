import React, { useEffect, useState } from 'react';
import { FormGroup, TextField, Box } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import TextLabel from 'components/login/TextLabel';
import LinkStyled from 'components/linkStyled/linkStyled';
import { alertObjectType } from 'utils/types';
import { checkEmailZod } from 'utils/validate';

const RequestNewActivationForm: React.FC<{
  username: string;
  setUsername: (newValue: string) => void;
  errors: string;
  hasUsernameError: boolean;
  isLoading: boolean;
  onRequestActivation: () => void;
  setAlert: (alertObject: alertObjectType | undefined) => void;
  backNavigation: () => void;
}> = ({
  username,
  setUsername,
  errors,
  hasUsernameError,
  isLoading,
  onRequestActivation,
  setAlert,
  backNavigation,
}) => {
  const [emailHelperText, setEmailHelperText] = useState('');
  const [emailIsValid, setEmailIsValid] = useState(false);

  const onEmailChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const emailValue = e.target?.value;

    setUsername(emailValue ? emailValue : '');

    if (!emailValue) {
      setEmailHelperText('');
    }

    if (emailValue.length > 0 && emailValue) {
      const isValidated = checkEmailZod(emailValue);
      setEmailIsValid(isValidated);
      if (!isValidated) {
        setEmailHelperText('Must be email format.');
        return;
      }
      setEmailHelperText('');
    }
  };

  const requestHandler = () => {
    if (username) {
      onRequestActivation();
    }
  };

  useEffect(() => {
    if (errors) {
      setAlert({ type: 'warning', message: errors, timeout: 5000 });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors]);

  return (
    <React.Fragment>
      <FormGroup>
        <TextLabel className="text-heading" variant="subtitle2" gutterBottom>
          Request new activation link
        </TextLabel>
        <TextField
          autoComplete="username"
          sx={{ mt: 1, mb: 3, width: '100%' }}
          label={'Email address'}
          value={username}
          onChange={onEmailChangeHandler}
          error={hasUsernameError || !!emailHelperText}
          helperText={emailHelperText}
          autoFocus
        />
        <Box
          sx={{ mt: 1 }}
          display="flex"
          justifyContent="center"
          alignItems="baseline"
          flexDirection="column"
          width="inherit"
        >
          <LoadingButton
            type="submit"
            onClick={requestHandler}
            variant="contained"
            fullWidth={true}
            disabled={!emailIsValid}
            size="large"
            loading={isLoading}
          >
            Request Activation Link
          </LoadingButton>

          <LinkStyled
            onClick={() => {
              backNavigation();
            }}
            sx={{
              mt: 3,
            }}
          >
            Return to sign in
          </LinkStyled>
        </Box>
      </FormGroup>
    </React.Fragment>
  );
};

export default RequestNewActivationForm;
