import { MaintenanceConfig, MaintenanceType } from '../../../infrastructure/lib/constants';

declare global {
  interface Window {
    MAINTENANCE_CONFIG: MaintenanceConfig;
  }
}

export const localMaintenanceConfig: MaintenanceConfig = {
  maintenanceStatus: 'NONE' as MaintenanceType,
  activeMaintenanceText:
    'We have an upcoming scheduled maintenance for {{product}} on: {{start_date}} to {{end_date}}.',
  plannedMaintenanceStartDate: '01/01/2024',
  plannedMaintenanceEndDate: '31/12/2024',
};

// return config for the environment we are compiled for
const maintenanceConfig =
  process.env.NODE_ENV === 'development' ? localMaintenanceConfig : window.MAINTENANCE_CONFIG;
export const MAINTENANCE_CONFIG = maintenanceConfig as MaintenanceConfig;
