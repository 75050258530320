import React, { useEffect, useState } from 'react';
import { Alert, Box, Container, Fade } from '@mui/material';
import { CheckCircleOutline } from '@mui/icons-material';
import { useNavigate, useSearchParams } from 'react-router-dom';
import IdobaLogo from '../../components/icons/IdobaLogin';
import ActivateForm from 'components/activate/ActivateForm';
import RequestNewActivationForm from 'components/activate/RequestNewActivationForm';
import LoginFooter from 'components/login/LoginFooter/LoginFooter';
import { alertObjectType, activateFormsTypes } from 'utils/types';
import { useIdobaAuthV2 } from '@optika-solutions/shared-react';

const Activate: React.FC = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [challenge, setChallenge] = useState('');
  const [session, setSession] = useState('');
  const [errors, setErrors] = useState('');
  const [loading, setLoading] = useState(false);
  const [hasUsernameError, setHasUsernameError] = useState(false);
  const [hasPasswordError, setHasPasswordError] = useState(false);
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const [displayView, setDisplayView] = useState<activateFormsTypes>(activateFormsTypes.activate);
  const { authService } = useIdobaAuthV2();

  const [alertObject, setAlertObject] = useState<alertObjectType | undefined>(undefined);
  const [showAlert, setShowAlert] = useState(false);

  useEffect(() => {
    if (alertObject) {
      setShowAlert(true);
    }
    if (alertObject?.timeout) {
      const alertTimer = setTimeout(() => {
        setShowAlert(false);
      }, alertObject.timeout);

      return () => clearTimeout(alertTimer);
    }
  }, [alertObject]);

  const activate = () => {
    setLoading(true);
    setErrors('');
    setHasUsernameError(false);
    setHasPasswordError(false);

    authService
      .activate(challenge, username, password, session)
      .then((result) => {
        //console.log(result);
        navigate('/modules');
        setLoading(false);
      })
      .catch((error: Error) => {
        console.log(error);
        setLoading(false);
      });
  };

  const requestLink = () => {
    const activation_code = params.get('token');
    if (activation_code) {
      setLoading(true);
      setErrors('');
      setHasUsernameError(false);
      authService
        .regenerate(username, activation_code)
        .then((result) => {
          //console.log(result);
          setLoading(false);
        })
        .catch((error: Error) => {
          console.log(error);
          setLoading(false);
        });
    }
  };

  const backToLogin = () => {
    navigate('/login');
  };

  useEffect(() => {
    const activation_code = params.get('token');
    if (activation_code) {
      setLoading(true);
      authService
        .checkActivation(activation_code)
        .then((result) => {
          setLoading(false);
          if (!result.success) {
            if (result.body.details && result.body.details.status_code === 400) {
              setDisplayView(activateFormsTypes.requestNewLink);
              setErrors(result.body.details.detail);
            } else if (result.body.message) {
              setHasPasswordError(true);
              setErrors(result.body.message);
            }
            return;
          }
          //populate the email
          setUsername(result.body.email);
          setChallenge(result.body.challenge_name);
          setSession(result.body.session);
        })
        .catch((error: Error) => {
          console.log(error);
          setLoading(false);
          setHasPasswordError(true);
          setAlertObject({
            type: 'error',
            message: 'An unknown error occured attempting to validate token',
          });
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate]);

  return (
    <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column">
      <Container sx={{ mt: 22 }} maxWidth="xs">
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
            height: 126,
            borderRadius: '4px',
            mb: '32px',
          }}
        >
          <IdobaLogo />
        </Box>

        {displayView === activateFormsTypes.activate && (
          <>
            <ActivateForm
              username={username}
              password={password}
              setUsername={setUsername}
              setPassword={setPassword}
              isLoading={loading}
              hasUsernameError={hasUsernameError}
              hasPasswordError={hasPasswordError}
              errors={errors}
              onActivate={activate}
              setAlert={setAlertObject}
            ></ActivateForm>
          </>
        )}

        {displayView === activateFormsTypes.requestNewLink && (
          <>
            <RequestNewActivationForm
              username={username}
              setUsername={setUsername}
              errors={errors}
              isLoading={loading}
              hasUsernameError={hasUsernameError}
              onRequestActivation={requestLink}
              setAlert={setAlertObject}
              backNavigation={backToLogin}
            ></RequestNewActivationForm>
          </>
        )}

        <LoginFooter />
      </Container>
      <Fade
        in={showAlert}
        easing={{ exit: 'ease-out' }}
        timeout={{ enter: 0, exit: 1000 }}
        onExited={() => setAlertObject(undefined)}
      >
        <Alert
          sx={{
            position: 'absolute',
            top: '56px',
            zIndex: 20,
          }}
          iconMapping={{
            success: <CheckCircleOutline fontSize="inherit" />,
          }}
          onClose={!alertObject?.timeout ? () => setShowAlert(false) : undefined}
          severity={alertObject?.type}
        >
          {alertObject?.message}
        </Alert>
      </Fade>
    </Box>
  );
};

export default Activate;
