import React, { useEffect, useState } from 'react';
import { Fade, FormGroup, TextField } from '@mui/material';
import Box from '@mui/system/Box';
import LoadingButton from '@mui/lab/LoadingButton';
import TextLabel from './TextLabel';
import { checkEmailZod } from 'utils/validate';
import LinkStyled from 'components/linkStyled/linkStyled';
import { loginErrorTypes } from 'utils/types';

const RequestPasswordResetForm: React.FC<{
  username: string;
  setUsername: (newValue: string) => void;
  requestReset: () => void;
  backNavigation: () => void;
  hasUsernameError: boolean;
  isLoading: boolean;
  errors: string;
  hasReadError: (errorType: loginErrorTypes) => void;
}> = ({
  username,
  setUsername,
  requestReset,
  backNavigation,
  hasUsernameError,
  isLoading,
  errors,
  hasReadError,
}) => {
  const [localEmail, setLocalEmail] = useState('');
  const [emailHelperText, setEmailHelperText] = useState('');
  const emailResponseText = 'Must be email format.';

  const onEmailChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLocalEmail(e.target?.value);
    if (e.target?.value) {
      const isValidEmail = checkEmailZod(e.target?.value);
      if (!isValidEmail) {
        setEmailHelperText(emailResponseText);
        return;
      }
      setUsername(e.target?.value);
      setEmailHelperText('');
      hasReadError(loginErrorTypes.username);
    }
  };

  // On Render, if autofilled and valid, then send to parent
  useEffect(() => {
    const autofilledIsValid = checkEmailZod(localEmail);
    if (!autofilledIsValid && localEmail) {
      setEmailHelperText(emailResponseText);
      return;
    }

    setUsername(localEmail);
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmitKeyHandler = (e: { code: string }) => {
    if (e.code === 'Enter') {
      requestReset();
    }
  };

  return (
    <React.Fragment>
      <Fade in appear>
        <Box sx={{ width: '100%', maxWidth: 500 }} mb={2}>
          <TextLabel className="text-heading" variant="subtitle2" gutterBottom>
            Reset your DiiMOS password
          </TextLabel>
        </Box>
      </Fade>

      <Fade in appear>
        <div>
          <FormGroup>
            <TextField
              autoComplete="email"
              label={'Email address'}
              value={localEmail}
              onChange={onEmailChangeHandler}
              onKeyDown={onSubmitKeyHandler}
              error={!!emailHelperText}
              helperText={emailHelperText || errors}
              focused={true}
              disabled={isLoading ? true : false}
            />
          </FormGroup>
          <Box
            sx={{ mt: 3 }}
            display="flex"
            justifyContent="center"
            alignItems="baseline"
            flexDirection="column"
            width="inherit"
          >
            <LoadingButton
              variant={'contained'}
              fullWidth={true}
              onClick={requestReset}
              disabled={isLoading || !localEmail || !!emailHelperText}
              size="large"
              loading={isLoading}
              sx={{ mt: 0 }}
            >
              Request password reset link
            </LoadingButton>

            <LinkStyled
              onClick={() => {
                backNavigation();
              }}
              sx={{
                mt: 3,
              }}
            >
              Return to sign in
            </LinkStyled>
          </Box>
        </div>
      </Fade>
    </React.Fragment>
  );
};

export default RequestPasswordResetForm;
