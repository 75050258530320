import React, { useEffect, useState } from 'react';
import { FormGroup, Box, Fade } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import TextLabel from 'components/login/TextLabel';
import PasswordInput from 'components/login/PasswordInput';
import { validatePassword } from 'utils/validate';
import ErrorLabel from 'components/login/ErrorLabel';
import { alertObjectType } from 'utils/types';
import PasswordValidation from 'components/login/PasswordValidation';

const PasswordResetForm: React.FC<{
  password: string;
  setPassword: (newValue: string) => void;
  errors: string;
  hasPasswordError: boolean;
  isLoading: boolean;
  onSubmit: () => void;
  setAlert: (alertObject: alertObjectType | undefined) => void;
}> = ({ password, setPassword, errors, hasPasswordError, isLoading, onSubmit, setAlert }) => {
  const [passwordError, setPasswordError] = useState('');

  const checkPassword = (text: string) => {
    const validationError = validatePassword(text);

    if (validationError) {
      setPasswordError(validationError);
      setPassword(text);
      return;
    }

    setPasswordError('');
    setPassword(text);
  };

  const submitHandler = () => {
    if (password) {
      onSubmit();
    }
  };

  useEffect(() => {
    if (errors) {
      setAlert({ type: 'warning', message: errors, timeout: 5000 });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors]);

  return (
    <React.Fragment>
      <FormGroup>
        <Fade in appear>
          <Box sx={{ width: '100%', maxWidth: 500 }} mb={2}>
            <TextLabel className="text-heading" variant="subtitle2" gutterBottom>
              Change your DiiMOS password
            </TextLabel>
          </Box>
        </Fade>

        <Box mb={2}>
          <PasswordValidation password={password}></PasswordValidation>
        </Box>

        <PasswordInput
          placeholder="Create a password"
          isLoading={isLoading}
          errors={passwordError}
          password={password}
          hasPasswordError={!!passwordError}
          onChange={checkPassword}
          onSubmitKeyHandler={submitHandler}
          isNewPassword
        />
        {passwordError && <ErrorLabel>{passwordError}</ErrorLabel>}

        <Box
          sx={{ mt: 3 }}
          display="flex"
          justifyContent="center"
          alignItems="baseline"
          flexDirection="column"
          width="inherit"
        >
          <LoadingButton
            type="submit"
            onClick={submitHandler}
            variant="contained"
            fullWidth={true}
            disabled={!!passwordError}
            size="large"
            loading={isLoading}
          >
            Change your password
          </LoadingButton>
        </Box>
      </FormGroup>
    </React.Fragment>
  );
};

export default PasswordResetForm;
